function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
import { Parser } from "../Parser.js";
import { numericPatterns } from "../constants.js";
import { parseNumericPattern, parseNDigits } from "../utils.js";
export class SecondParser extends Parser {
  constructor() {
    super(...arguments);
    _defineProperty(this, "priority", 50);
    _defineProperty(this, "incompatibleTokens", ['t', 'T']);
  }
  parse(dateString, token, match) {
    switch (token) {
      case 's':
        return parseNumericPattern(numericPatterns.second, dateString);
      case 'so':
        return match.ordinalNumber(dateString, {
          unit: 'second'
        });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 59;
  }
  set(date, _flags, value) {
    date.setUTCSeconds(value, 0);
    return date;
  }
}