function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
import { Parser } from "../Parser.js";
import { parseNDigitsSigned } from "../utils.js";
import startOfUTCISOWeek from "../../../_lib/startOfUTCISOWeek/index.js"; // ISO week-numbering year

export class ISOWeekYearParser extends Parser {
  constructor() {
    super(...arguments);
    _defineProperty(this, "priority", 130);
    _defineProperty(this, "incompatibleTokens", ['G', 'y', 'Y', 'u', 'Q', 'q', 'M', 'L', 'w', 'd', 'D', 'e', 'c', 't', 'T']);
  }
  parse(dateString, token) {
    if (token === 'R') {
      return parseNDigitsSigned(4, dateString);
    }
    return parseNDigitsSigned(token.length, dateString);
  }
  set(_date, _flags, value) {
    var firstWeekOfYear = new Date(0);
    firstWeekOfYear.setUTCFullYear(value, 0, 4);
    firstWeekOfYear.setUTCHours(0, 0, 0, 0);
    return startOfUTCISOWeek(firstWeekOfYear);
  }
}